export default (locale: string) => {
  switch (locale) {
    case 'zh-Hans':
      return 'zh-cn'
      break
    default:
      return locale
      break
  }
  return locale
}
