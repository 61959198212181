<script setup lang="ts">
import { TransitionPresets, useStorage } from '@vueuse/core'
const video = ref()
const BGPlay = useBGPlay()
const playbackRate = computed(() => {
  const route = useRoute()
  return route.name == 'index' || route.name == 'login'
    ? 0.15
    : route.name == 'usercp'
      ? 0.3
      : 0.07
})
const transitionPlaybackRate = useTransition(playbackRate, {
  duration: 1000,
  transition: TransitionPresets.easeInOutCubic,
})

watch(BGPlay, (v) => {
  if (!loading.value) {
    if (v) {
      video.value.play()
    } else {
      video.value.pause()
    }
  }
})
const liveSource = useStorage('BGsrc-store', '', localStorage)
const loading = ref(true)
const handleCanplay = () => {
  loading.value = false
  video.value.play()
}
</script>
<template>
  <video
    id="video"
    ref="video"
    :class="[
      !loading ? '' : 'opacity-0',
      'min-h-full min-w-full object-cover transition-all duration-300',
    ]"
    preload="metadata"
    autobuffer
    muted
    loop
    playsinline
    disablepictureinpicture
    disableremoteplayback
    defaultPlaybackRate="0.1"
    :playbackRate="transitionPlaybackRate"
    @canplay="handleCanplay"
  >
    <source :src="liveSource" >
    <source :src="'https://files.orcinusorca.org/whale.mp4'" type="video/mp4" >
    <source
      :src="'https://files.orcinusorca.org/whale-avc.mp4'"
      type="video/mp4"
    >
    <source
      :src="'https://files.orcinusorca.org/whale.mov'"
      type="video/quicktime"
    >
    <img
      class="min-h-full min-w-full object-cover object-center"
      src="https://img.ptdream.net/o/ptdream-img/2024/08/28/backg.jpg"
    >
  </video>
</template>
