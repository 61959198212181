<script setup lang="ts">
const route = useRoute()
const id = computed(() => {
  const patharr = route.path.split('/')
  return patharr[1] == 'torrent' ? Number(patharr[2]) : 1
})

const { data: torrentData } = await useHyc('/api/v1/torrent/{torrent}', {
  key: 'torrent-' + id.value,
  path: { torrent: id },
  cache: 'reload',
  lazy: true,
})

const links = computed(() => {
  const patharr = route.path.split('/')
  patharr.shift()
  let pathStr = ''
  return patharr.map((v, i, a) => {
    pathStr += '/'
    pathStr += v
    if (a[0] == 'torrent' && i == 1) {
      return {
        label: torrentData.value
          ? torrentData.value.data.name.length > 8
            ? torrentData.value.data.name.slice(0, 8) + '...'
            : torrentData.value.data.name
          : '',
        to: pathStr,
      }
    } else {
      return {
        label: v,
        to: pathStr,
      }
    }
  })
})
</script>

<template>
  <UBreadcrumb :links="links" />
</template>
