export type UserInfo = {
  id: number | undefined
  ip: string | undefined
  title: string | undefined
  status: string | undefined
  name: string | undefined
  upload_volume: string | undefined
  download_volume: string | undefined
}
export const base64ToBytes = (base64: string) => {
  const binString = atob(base64)
  return Uint8Array.from(binString, (m) => m.codePointAt(0))
}
export const base64ToText = (base64?: string) => {
  return new TextDecoder().decode(
    base64ToBytes(base64 || '8J+QseermfCfjLbvuI7wn5CUCg=='),
  )
}
