<script setup lang="ts">
const form = useState('loginForm', () => 'login')
const { locale, locales, setLocale } = useI18n()

const i18nItems = computed(() => {
  return [
    locales.value
      .filter((i) => i.code !== locale.value)
      .map((v) => ({
        label: v.name,
        click: () => {
          setLocale(v.code)
        },
      })),
  ]
})
const i18nOpen = ref(false)
const isLogin = useisLogin()
const { status: userStatus } = await useHyc('/api/v1/user/data', {
  key: 'userInfo',
  dedupe: 'defer',
  lazy: true,
})

const loading = ref(
  userStatus.value === 'idle' || userStatus.value === 'pending',
)
isLogin.value = userStatus.value === 'success'
watch(userStatus, (v) => {
  switch (v) {
    case 'success':
      isLogin.value = true
      loading.value = false
      break
    case 'error':
      isLogin.value = false
      loading.value = false
      break
    default:
      break
  }
})
</script>
<template>
  <div
    v-if="$route.name != 'login'"
    :class="[
      loading
        ? 'backdrop-blur'
        : !isLogin
          ? 'backdrop-blur-lg'
          : 'pointer-events-none touch-none backdrop-blur-0',
      'fixed top-0 z-50 flex h-dvh w-full items-center justify-center overflow-y-auto transition-all',
    ]"
  >
    <div
      v-if="!isLogin"
      id="login-win"
      :class="[
        isLogin ? '-translate-y-20 opacity-5' : 'translate-y-0 opacity-100',
        'bg-primary-50/10 shadow-primary-300/10 dark:shadow-primary-800/10 relative m-3 flex w-96 flex-col items-center rounded p-2 pb-10 shadow-xl ring-1 ring-black/5 backdrop-saturate-200 transition-all dark:backdrop-brightness-50',
      ]"
    >
      <span class="m-1 text-3xl">HDDolby</span>
      <span v-if="form == 'login'" class="m-1 text-xl">{{ $t('Login') }}</span>
      <span v-if="form == 'signup'" class="m-1 text-xl">Signup</span>
      <TransitionGroup class="h-full w-full overflow-y-auto" tag="div">
        <LoginForm v-if="form == 'login'" key="loginf" />
        <SignupForm v-if="form == 'signup'" key="signupf" />
      </TransitionGroup>
      <div class="absolute bottom-4 flex items-center justify-center">
        <UButton color="gray" variant="link">W B M?</UButton>
        <span class="px-4 text-gray-400">|</span>
        <UButton color="gray" variant="link">contact</UButton>
        <span class="px-4 text-gray-400">|</span>
        <UDropdown
          v-model:open="i18nOpen"
          :items="i18nItems"
          :popper="{ strategy: 'absolute', placement: 'left-start' }"
        >
          <UButton
            color="gray"
            variant="link"
            label="i18n"
            trailing-icon="icon-park-outline:international"
          />
        </UDropdown>
      </div>
    </div>
    <div v-if="loading">loading...</div>
  </div>
</template>
