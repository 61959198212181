<script setup lang="ts">
// import type { Level, RenderAs } from 'qrcode.vue'
// import Qrcode from 'qrcode.vue'

// const level = ref<Level>('H') //L H
// const renderAs = ref<RenderAs>('svg')
const { data: userInfo } = await useHyc('/api/v1/user/data', {
  key: 'userInfo',
  dedupe: 'defer',
  lazy: true,
})
const { now, pause, resume } = useNow({ controls: true })
const idstring = ref('')
const focused = useWindowFocus()
watchThrottled(
  focused,
  () => {
    resume()
    let newStr = userInfo.value?.data
      ? now.value +
        base64ToText() +
        userInfo.value.data.id +
        userInfo.value.data.ip
      : base64ToText()
    pause()
    while (newStr.length < 30000) {
      newStr += newStr
    }
    idstring.value = newStr
  },
  { throttle: 5000 },
)
</script>
<template>
  <div
    v-if="idstring"
    class="pointer-events-none fixed top-0 z-40 h-dvh w-[200rem] touch-none select-none items-center justify-around break-all leading-3 opacity-[0.005]"
  >
    <!-- <span v-for="i in 600" :key="i" class="bg-white p-[2px]">
      <Qrcode
      v-if="idstring"
        class="size-16"
        :value="idstring"
        :level="level"
        :render-as="renderAs"
      />
    </span> -->
    <span class="text-red-400">
      {{ idstring }}
    </span>
  </div>
</template>
